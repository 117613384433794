import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger/src";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga/indexSaga";
import authReducer from "./reducers/AuthReducer";
import profileReducer from "./reducers/ProfileReducer";
import dashboardReducer from "./reducers/DashboardReducer";

const sagaMiddleware = createSagaMiddleware();

const middleWares = [];

if (process.env.NODE_ENV !== "production") {
  middleWares.push(logger);
}
const store = createStore(
  combineReducers({
    authReducer,
    profileReducer,

    dashboard: dashboardReducer,
  }),
  compose(composeWithDevTools(applyMiddleware(sagaMiddleware, ...middleWares)))
);

sagaMiddleware.run(rootSaga);

export default store;
