import {
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from "../../utils/constant";
import _ from "lodash";
const auth = localStorage.getItem("supplier");

const initialState = {
  auth: !!auth ? JSON.parse(auth) : {},
  isAuthenticated: !_.isEmpty(auth),
  isLoading: false,
  errors: {},
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("supplier", JSON.stringify(payload.data));
      return {
        ...state,
        auth: payload,
        isAuthenticated: true,
        isLoading: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("supplier");
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        auth: {},
      };
    default:
      return state;
  }
};

export default authReducer;
